export default {
  tableFields: [
    {
      name: '__component:badge-column',
      title: '',
      dataClass: 'text-center',
      width: '4%'
    },
    {
      name: 'name',
      sortField: 'name',
      width: '20%'
    },
    {
      name: 'code',
      sortField: 'code',
      title:'KTAG',
      width: '11%'
    },
    {
      name: 'email',
      sortField: 'email',
      width: '20%'
    },

    {
      name: 'event.name',
      title: 'Event',
      width: '15%'
    },
    {
      name:       '__slot:active',
      title:      'ACTIONS',
      titleClass: "center aligned",
      dataClass:  "center aligned",
      width:      "20%",
    }
  ],
  sortFunctions: {
    'name': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    },
    'location': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    }
  }
}
