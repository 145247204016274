<template>
<div class="content">
    <div class="row">

        <div class="col-md-12">
            <vuestic-widget :headerText="$t('view.staff.title')">
                <div class="row">
                    <div class="col-md-3">
                        <button type="button" v-on:click="back" class="btn btn-primary btn-sm">{{'view.staff.back' | translate}}</button>
                    </div>
                    <div class="col-md-3 offset-md-6" v-show="statusEvent === 1">
                        <button type="button" v-on:click="trashStaff" class="btn btn-warning btn-sm">{{'view.staff.delete' | translate}}</button>
                    </div>
                </div>
            </vuestic-widget>
        </div>
        <div class="col-md-12">
            <vuestic-widget :headerText="header+': '+codektag">

                <!-- <div class="row">
                    <div class="col-md-6">
                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}">
                            <div class="input-group">
                                <input id="name" name="name" v-model="name" v-validate="'required'" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="name">{{'view.staff.fields.name' | translate}} </label><i class="bar"></i>
                                <small v-show="errors.has('name')" class="help text-danger">
                                                        {{ errors.first('name') }}
                                                    </small>
                            </div>
                        </div>
                          <div class="form-group with-icon-right" :class="{'has-error': errors.has('code'), 'valid': isSuccessfulNameValid}">
                            <div class="input-group">
                                <input id="code" code="code" v-model="code" v-validate="'required'" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="code">{{'view.staff.fields.code' | translate}} </label><i class="bar"></i>
                                <small v-show="errors.has('code')" class="help text-danger">
                                                        {{ errors.first('code') }}
                                                    </small>
                            </div>
                        </div>
                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('email'), 'valid': isSuccessfulLocationValid}">
                            <div class="input-group">
                                <input id="email" name="email" v-model="email" v-validate="'required'" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="email">{{'view.staff.fields.email' | translate}} </label><i class="bar"></i>
                                <small v-show="errors.has('email')" class="help text-danger">
                                                        {{ errors.first('email') }}
                                                    </small>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6">

                        <vuestic-simple-select :label="'view.staff.fields.typestaff' | translate" v-model="idtypestaff" option-key="name" v-bind:options="typestaffs">
                        </vuestic-simple-select>

                    </div>
                </div>
                  <div class="row">
                    <div class="col-md-8">
                    </div>
                    <div class="col-md-4">
                        <label class="control-label" for="location">{{'view.station.fields.status' | translate}} </label>

                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('active')}">

                            <div class="input-group">
                                <vuestic-switch v-model="active">
                                    <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                                    <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                                </vuestic-switch>
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>

                                <small v-show="errors.has('active')" class="help text-danger">
                                                        {{ errors.first('active') }}
                                                    </small>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}">
                            <div class="input-group">
                                <input id="name" name="name" v-model="name" v-validate="'required'" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="name">{{'view.staff.fields.name' | translate}} </label><i class="bar"></i>
                                <small v-show="errors.has('name')" class="help text-danger">
                                                        {{ errors.first('name') }}
                                                    </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('email'), 'valid': isSuccessEmailValid}">
                            <div class="input-group">
                                <input id="email" name="email" v-model="email" v-validate="'required'" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="email">{{'view.staff.fields.email' | translate}} </label><i class="bar"></i>
                                <small v-show="errors.has('email')" class="help text-danger">
                                                        {{ errors.first('email') }}
                                                    </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 offset-md-6">
                        <label class="control-label" for="location">{{'view.station.fields.status' | translate}} </label>

                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('active')}">

                            <div class="input-group">
                                <vuestic-switch v-model="active">
                                    <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                                    <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                                </vuestic-switch>
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>

                                <small v-show="errors.has('active')" class="help text-danger">
                                                        {{ errors.first('active') }}
                                                    </small>
                            </div>
                        </div>

                        <!-- <vuestic-simple-select :label="'view.staff.fields.typestaff' | translate" v-model="idtypestaff" option-key="name" v-bind:options="typestaffoptions">
                        </vuestic-simple-select> -->

                    </div>
                </div>
                <div class="row" v-show="statusEvent != 3">
                    <button type="button" v-on:click="editStaff" class="btn btn-primary btn-sm">{{'view.staff.update' | translate}}</button>
                </div>

            </vuestic-widget>

        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import TableStaff from '../tables/TableStaff/TableStaff.vue'

import {
  SpringSpinner
} from 'epic-spinners'
import {
  mapState,
  mapActions
} from 'vuex'
export default {
  name: 'new-staff',
  components: {
    SpringSpinner,
    TableStaff
  },
  mounted () {
    var id = this.$route.params.id
    if (id !== null) {
      this.loadStaff(id)
        .then(data => {
          this.name = data.name
          this.email = data.email
          this.idevent = data.id_event
          this.active = data.active
          this.codektag = data.user_ktag.codektag;
        })
        .catch(data => {
          this.addToastMessage({
            text: 'Ocurrio un error',
            type: 'success'
          })
        })
    } else {
      this.addToastMessage({
        text: 'Ocurrio un error',
        type: 'success'
      })
    }
    // checkbox and radios
    this.loadTypestaffsActive()
      .then(data => {
        // this.typestaffoptions = this.typestaffs;
      })
      .catch(data => {
        this.addToastMessage({
          text: 'Error consultando usuarios',
          type: 'warning'
        })
      })
    // this.loadEvents()
    //     .then(() => {
    //         this.eventoptions = this.events;
    //     })
    //     .catch(data => {
    //         this.addToastMessage({
    //             text: "Error consultando usuarios",
    //             type: "warning"
    //         });
    //     });
  },
  computed: {
    ...mapState({
      typestaffs: state => state.typestaff.typestaffs,
      events: state => state.event.events,
      statusEvent: state => state.event.event.status,
     // idevent: state => state.event.event.id,
    }),
    isSuccessfulNameValid () {
      let isValid = false
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid
      }
      return isValid
    },
    isSuccessEmailValid () {
      let isValid = false
      if (this.formFields.email) {
        isValid =
                    this.formFields.email.validated && this.formFields.email.valid
      }
      return isValid
    }
  },
  data () {
    return {
      header: 'Actualizar Staff',
      typestaffoptions: null,
      name: '',
      code: '',
      email: '',
      codektag:'',
      idevent:'',
      idtypestaff: '',
      eventoptions: null,
      status: null,
      active: 1
    }
  },
  methods: {
    ...mapActions([
      'loadTypestaffsActive',
      'updateStaff',
      'deleteStaff',
      'loadStaff',
      'addToastMessage'
    ]),
    back () {
      this.$router.replace('/staff')
    },
    editStaff () {
      this.$validator.validate().then(result => {
        if (result) {
          var id = this.$route.params.id
          this.updateStaff({
            name: this.name,
            code: this.code,
            email: this.email,
            id_type_staff: this.idtypestaff.id,
            active: this.active,
            id_event:this.idevent,
            id: id
          })
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })
              setTimeout(() => this.$router.replace('/staff'), 2000)
            })
            .catch(data => {
              this.error = data.message
              this.errors = data.errors || {}
            })
          // this.$refs.vuetable.refresh();
        }
      })
    },
    trashStaff () {
      var id = this.$route.params.id
      console.log(id)
      this.deleteStaff({
        id: id
      })
        .then((data) => {
          this.addToastMessage({
            text: data.message,
            type: 'warning'
          })
          setTimeout(() => this.$router.go(-1), 100)
        })
        .catch(data => {
          this.error = data.message
          this.errors = data.errors || {}
        })
      // this.$refs.vuetable.refresh();
    },
    cleanform () {
      this.name = null
      this.description = null
    }
  }
}
</script>

<style lang="scss">
.color-icon-label-table {
    td:first-child {
        width: 1rem;
    }
}
</style>
